<template>
  <v-app>
    <!-- <v-app-bar
      app
      color="reimolemmik"
      dark
    >
    <v-spacer></v-spacer>
    <router-link to="/">Home</router-link>
    <v-spacer></v-spacer>
    <router-link to="about">About</router-link>
    <v-spacer></v-spacer>
    </v-app-bar> -->

    <v-main>
      <v-btn
        @click="navToProjects()"
        style="position: fixed;bottom:0"
      >Projektid</v-btn>
      <router-view></router-view>
      <!-- <div style="position:fixed;bottom:50px;left:0" class="devDebugInfo">
        <div><span>emailSent: </span><span>{{emailSent}}</span></div>
        <div><span>emailSendingState: </span><span>{{emailSendingState}}</span></div>
      </div> -->
    </v-main>
  </v-app>
</template>

<script>
import router from "@/router"; 

export default {
  name: 'App',

  components: {
    // HelloWorld,
  },
  mounted(){
    if(window.location.search.substring(1).indexOf("path=")==0){
      router.push({path:"/"+window.location.search.substring(6)})
    }

  },
  computed:{
      emailSendingState(){
        return this.$store.getters.emailSendingState
      },
      emailSent(){
        return this.$store.getters.emailSent
      }
    },
  methods:{
    navToProjects(){
      router.push({path:"/"})
    }
  }
};
</script>
<style lang="scss">
@import '@/style/variables.scss';
@import '@/style/main.scss';
  
</style>
