import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    emailSendingState:"",
    emailSent:false,
    loading:false,
  },
  mutations: {
    setLoading(state,payload){
      state.loading=payload
    },
    setEmailSendingState(state,payload){
      state.emailSendingState=payload
    },
    setEmailSent(state,payload){
      state.emailSent=payload
    }
  },
  actions: {
    
    async sendEmail(state,payload) {
      state.commit("setLoading",true)
      try{
        var url = new URL(window.location.origin+"/sendEmail.php");
      
        Object.keys(payload).forEach(key => url.searchParams.append(key, payload[key]))
        var response = await fetch(url);
        var data = await response.json();
        if("status" in data && data.status=="OK"){
          state.commit("setEmailSendingState","success")
        }else{
          state.commit("setEmailSendingState","error")
        }
        state.commit("setEmailSent",true)
  
        console.log(data)
      }catch(e){
        state.commit("setEmailSent",true)
        state.commit("setEmailSendingState","error")
      }
      window.setTimeout(function(){
        state.commit("setEmailSent",false)
        window.setTimeout(function(){
          state.commit("setEmailSendingState","")
        },500)
      },5000)
      state.commit("setLoading",false)
      
    }
    
  },
  getters: {
    emailSendingState: (state) => state.emailSendingState,
    emailSent: (state) => state.emailSent,
    loading: (state) => state.loading
  }
})
