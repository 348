import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#B88768',
            secondary: '#D7926B',
            action: '#6695AC',
            action2: '#CAA892',
            action2outline: '#d8c4b7',
            error: '#b71c1c',
            dark:"#192022",
            alternate:"#EDC15E",
          },
        },
        customVariables: ['@/style/variables.scss'],
      },
});
