<template>
  <div class="home">
    <v-app-bar
    color="primary"
    dark>
      <div class="text-h4">Eleg8</div>  
    </v-app-bar>

    <v-container
      class="mt-10"
    >
      <v-row
        justify="center"
        class="d-flex flex-column text-center"
      >

        <div class="text-h2 mb-10">
          Meie projektid:
        </div>
        <v-btn class="my-3 test" color="action" dark @click="navToProject('1')">Jõeääre kodud</v-btn>
        <v-btn class="my-3" color="action" dark @click="navToProject()">Tartu kodud</v-btn>
        <v-btn class="my-3" color="action" dark @click="navToProject()">Paide kodud</v-btn>
        <v-btn class="my-3" color="action" dark @click="navToProject()">Muud kodud</v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import router from "@/router"; 
export default {
  name: 'Home',
  components: {
    
  },
  methods:{
    navToProject(idx){
      switch(idx){
        case "1":
          router.push({name:"Joeaare"})
          break
        default:
          router.push({name:"notFound"})
          break
      }
    }
  }
}
</script>
<style>

.homeBG .v-image__image{
  background-size: cover !important;
}
.homeBG{
  height:100vh;
  width:100%;
  overflow-y: hidden;
}
</style>